 /* @copyright Rug Pull Games, Project: RPG 404 */
 /* @see Rug Pull Games: https://rug-pull.games/ */
 /* @see RPG 404: http://rpg404.com/ */
 /* @author endaye */

.logo {
  position: absolute;
  top: 0;
  left: 0;
}

.logo-rpg-icon {
  position: absolute;
  height: 60px;
  top: 30px;
  left: 0px;
  cursor: pointer;
  image-rendering: auto;
}

.logo-rpg-404-icon {
  position: absolute;
  height: 48px;
  top: 36px;
  left: 70px;
  cursor: not-allowed;
}

@media only screen and (max-width: 900px) {
  .logo-rpg-icon {
    position: absolute;
    height: 40px;
    top: 10px;
    left: 10px;
    cursor: pointer;
    image-rendering: auto;
  }

  .logo-rpg-404-icon {
    position: absolute;
    height: 32px;
    top: 14px;
    left: 50px;
  }
}
