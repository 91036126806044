 /* @copyright Rug Pull Games, Project: RPG 404 */
 /* @see Rug Pull Games: https://rug-pull.games/ */
 /* @see RPG 404: http://rpg404.com/ */
 /* @author endaye */

.navbar {
  position: relative;
  height: 120px;
  /* background-color: aquamarine; */
}

@media only screen and (max-width: 900px) {
  .navbar {
    position: relative;
    width: 100vw;
    height: 60px;
  }
}
