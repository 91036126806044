 /* @copyright Rug Pull Games, Project: RPG 404 */
 /* @see Rug Pull Games: https://rug-pull.games/ */
 /* @see RPG 404: http://rpg404.com/ */
 /* @author endaye */

.game {
  position: absolute;
  top: 120px;
  bottom: 0;
  left: -14px;
  right: -14px;
  overflow: hidden;
  /* background-color: rgba(255, 0, 0, 0.5); */
}

.game-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: -140px;
  bottom: 0;
  margin: auto;
  width: 100%;
  z-index: -1;
}

.game-iframe,
.game-cover {
  position: absolute;
  left: 14px;
  right: 14px;
  top: -140px;
  bottom: 0;
  margin: auto;
  width: 900px;
  height: 300px;
}

.game-iframe-show {
  display: block;
}

.game-iframe-hide {
  display: none;
}

.game-iframe-cover {
  display: none;
}

.game-cover-img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.game-guide {
  position: absolute;
  /* background-color: blueviolet; */
  right: 10px;
  bottom: 10px;
  font-size: 8px;
  color: white;
  text-align: left;
  pointer-events: none;
}

.btn-game-play {
  position: absolute;
  top: 100px;
  right: 300px;
  width: 80px;
  height: 80px;
  transition: transform 0.2s; /* Animation */
}

.btn-game-play:hover {
  transform: scale(1.2);
  filter: brightness(120%);
}

.btn-select-nft {
  position: absolute;
  top: 100px;
  right: 160px;
  width: 80px;
  height: 80px;
  transition: transform 0.2s; /* Animation */
}

.btn-clickable {
  cursor: pointer;
}

.btn-unclickable {
  cursor: not-allowed;
}

.btn-select-nft:hover {
  transform: scale(1.2);
  filter: brightness(120%);
}

.btn-game-rotate {
  display: none;
}

.btn-game-to-mint {
  position: absolute;
  right: 20px;
  bottom: min(20px, 4vh);
  cursor: pointer;
}

/* NFTPanel Animation */
.open {
  animation: bounce-in 0.2s ease-in;
}
.close {
  animation: bounce-out 0.2s ease-in;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bounce-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 900px) {
  .game {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    margin-top: cal(20vh - 10vw);
    height: 100vw;
    overflow: hidden;
    /* background-color: rgba(255, 0, 255, 0.5); */
  }

  .game-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    width: 100%;
    z-index: -1;
  }

  .game-iframe,
  .game-cover {
    position: absolute;
    left: 0;
    right: 0;
    top: -1.3vw;
    margin: auto;
    width: 100vw;
    height: 33.33333vw;
    /* background-color: blue; */
  }

  .game-cover-img {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .game-guide {
    position: relative;
    /* background-color: blueviolet; */
    left: 0;
    right: 0;
    bottom: 10px;
    font-size: 8px;
    color: white;
    text-align: right;
    pointer-events: none;
  }

  .btn-game-play {
    position: absolute;
    top: 25%;
    right: 36vw;
    width: 12vw;
    height: 12vw;
    transition: transform 0.2s; /* Animation */
  }

  .btn-game-play:hover {
    transform: scale(1.2);
    filter: brightness(120%);
  }

  .btn-select-nft {
    position: absolute;
    top: 25%;
    right: 12vw;
    width: 12vw;
    height: 12vw;
    transition: transform 0.2s; /* Animation */
  }

  .btn-clickable {
    cursor: pointer;
  }

  .btn-unclickable {
    cursor: not-allowed;
  }

  .btn-select-nft:hover {
    transform: scale(1.2);
    filter: brightness(120%);
  }

  .btn-game-to-mint {
    position: fixed;
    right: 20px;
    width: 80vw;
    bottom: 10vh;
    cursor: pointer;
  }
}

@media only screen and (max-width: 599px) {
  .game-iframe-rotate {
    position: fixed;
    transform: rotate(90deg);
    top: 0;
    bottom: 0;
    z-index: 400;
    margin-left: auto;
    margin-right: auto;
  }

  .game-iframe-cover-rotate {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 398;
    background-color: #000000cc;
  }

  .btn-game-rotate {
    display: block;
    position: fixed;
    left: 20px;
    bottom: 10vh;
    cursor: pointer;
    z-index: 399;
  }
}
