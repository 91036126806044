 /* @copyright Rug Pull Games, Project: RPG 404 */
 /* @see Rug Pull Games: https://rug-pull.games/ */
 /* @see RPG 404: http://rpg404.com/ */
 /* @author endaye */

.wallet-acc {
  position: absolute;
  top: 100px;
  right: 12px;
  font-size: 8px;
  text-align: right;
  color: white;
}

@media only screen and (max-width: 900px) {
  .wallet-acc {
    position: absolute;
    top: 48px;
    right: 12px;
    font-size: 8px;
    text-align: right;
    color: white;
  }
}
