.nft-panel {
  position: absolute;
  left: 14px;
  right: 14px;
  top: -140px;
  bottom: 0;
  margin: auto;
  width: 900px;
  height: 300px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
}

.nft-container {
  position: absolute;
  top: 0;
  bottom: -17px;
  left: 0;
  right: 0; /* Increase/Decrease this value for cross-browser compatibility */
  overflow-y: scroll;
  overflow: auto;
  white-space: nowrap;
  width: 100%;
  height: calc(100% + 17px);
}

.nft-item {
  position: relative;
  width: 200px;
  height: 200px;
  margin-top: 25px;
  margin-left: 20px;
  margin-right: 20px;
  vertical-align: center;
  background-color: black;
  display: inline-block;
}

.nft-item:first-child {
  margin-left: 40px;
}

.nft-item:last-child {
  margin-right: 40px;
}

.nft-item-loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 80px;
  height: 80px;
}

.nft-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 105%;
  height: 105%;
  filter: brightness(0.5);
  cursor: pointer;
}

.nft-image:hover {
  width: 105%;
  height: 105%;
  filter: brightness(1);
}

@media only screen and (max-width: 900px) {
  .nft-panel {
    position: absolute;
    left: 0;
    right: 0;
    top: -1.3vw;
    bottom: 0;
    margin: auto;
    width: 100vw;
    height: 33.33333vw;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .nft-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; /* Increase/Decrease this value for cross-browser compatibility */
    overflow-y: scroll;
    overflow: auto;
    white-space: nowrap;
    width: 100%;
  }

  .nft-item {
    position: relative;
    width: 24vw;
    height: 24vw;
    margin-top: 4vw;
    margin-left: 4vw;
    margin-right: 4vw;
  }

  .nft-item:first-child {
    margin-left: 8vw;
  }

  .nft-item:last-child {
    margin-right: 8vw;
  }

  .nft-item-loading {
    width: 24vw;
    height: 24vw;
  }
}
