 /* @copyright Rug Pull Games, Project: RPG 404 */
 /* @see Rug Pull Games: https://rug-pull.games/ */
 /* @see RPG 404: http://rpg404.com/ */
 /* @author endaye */

.App {
  position: relative;
  text-align: center;
  width: 900px;
  height: 100vh;
  min-height: 600px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  /* background-color: magenta; */
}

img {
  image-rendering: pixelated;
}

@media only screen and (max-width: 900px) {
  .App {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    height: -moz-available;
  }
}
