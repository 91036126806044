 /* @copyright Rug Pull Games, Project: RPG 404 */
 /* @see Rug Pull Games: https://rug-pull.games/ */
 /* @see RPG 404: http://rpg404.com/ */
 /* @author endaye */

.nft-detail {
  position: absolute;
  /* background-color: blueviolet; */
  top: 0px;
  width: 400px;
  height: 220px;
  font-size: 8px;
  color: white;
  text-align: left;
  z-index: 300;
  pointer-events: none;
}
