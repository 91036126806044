 /* @copyright Rug Pull Games, Project: RPG 404 */
 /* @see Rug Pull Games: https://rug-pull.games/ */
 /* @see RPG 404: http://rpg404.com/ */
 /* @author endaye */

.mint {
  position: absolute;
  top: 120px;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: blueviolet; */
}

.mint-market-bg {
  height: calc(100vh - 120px);
  margin-left: -100vw;
  margin-right: -100vw;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .mint {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    height: calc(85vh - 60px);
    /* background-color: blueviolet; */
    overflow: hidden;
  }

  .mint-market-bg {
    margin-left: -100px;
    margin-right: -100px;
    height: 40vh;
    cursor: pointer;
  }
}
