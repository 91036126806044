 /* @copyright Rug Pull Games, Project: RPG 404 */
 /* @see Rug Pull Games: https://rug-pull.games/ */
 /* @see RPG 404: http://rpg404.com/ */
 /* @author endaye */

.status {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  word-wrap: break-word;
  color: white;
  font-size: 8px;
  text-align: left;
  z-index: 200;
}

@media only screen and (max-width: 900px) {
  .status {
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    width: 100vw;
    margin-left: 0;
    margin-right: 0;
    word-wrap: break-word;
    color: white;
    font-size: 8px;
    text-align: left;
    z-index: 200;
  }
}
