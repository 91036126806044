 /* @copyright Rug Pull Games, Project: RPG 404 */
 /* @see Rug Pull Games: https://rug-pull.games/ */
 /* @see RPG 404: http://rpg404.com/ */
 /* @author endaye */

.social-links {
  position: absolute;
  top: 0;
  right: 0;
}

.social-icon {
  display: inline-block;
  height: 60px;
  width: 60px;
  margin-top: 30px;
  margin-left: 10px;
  cursor: pointer;
}

.social-icon-img {
  margin-top: -30px;
  margin-left: -30px;
  height: 120px;
  pointer-events: none;
}

@media only screen and (max-width: 900px) {
  .social-links {
    position: absolute;
    top: 0;
    right: 10px;
  }

  .social-icon {
    height: 20px;
    width: 30px;
    margin-top: 10px;
    margin-left: 10px;
  }

  .social-icon-img {
    margin-top: -10px;
    margin-left: -20px;
    height: 60px;
    pointer-events: none;
  }
}
